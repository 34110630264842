import { DetailView } from "component-library"

import "./settings.css"

export function Settings() {
	return (
		<div className="module-view">
			<DetailView
				defaultTab="general"
				detailViewObjectName="t-settings-title"
				tabDataProvider={{}}
				redirectPath="/settings"
				tabs={[]}
				viewProviderName="settings"
				backButton={false}
			/>
		</div>
	)
}
