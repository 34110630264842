import { ProviderStore, translate, ViewProvider } from "shared"
import { faMobile } from "@fortawesome/free-solid-svg-icons"
import QRCode from "react-qr-code"

import AppStoreBadge from "../../../assets/appStoreBadge.svg"
import PlayStoreBadge from "../../../assets/playStoreBadge.svg"
import LEAPAppLogo from "../../../assets/app-icon-LEAP-text.png"

import "./app.css"

export class AppInformation extends ViewProvider {
	groupName = "settings"
	name = translate("t-settings-app-tab-title")
	icon = faMobile
	priority = 600
	uniqueId = "app"
	component = () => {
		return (
			<div className="app-wrapper">
				<div className="app-information">
					<div className="app-information-header">
						<img className="app-logo" src={LEAPAppLogo} alt="LEAP App Logo" />
						<div>
							<span className="app-title">LHIND LEAP</span>
							<span className="app-subtitle">IoT Information as a Service</span>
						</div>
					</div>
					<p>{translate("t-app-general-information")}</p>
				</div>
				<div className="apps-qr-code-wrapper">
					<div>
						<QRCode value="https://apps.apple.com/app/lhind-leap/id1635565033" size={300} />
						<a href="https://apps.apple.com/app/lhind-leap/id1635565033" target="_blank" rel="noreferrer">
							<img src={AppStoreBadge} alt="Download on the App Store" />
						</a>
					</div>
					<div>
						<QRCode value="https://play.google.com/store/apps/details?id=com.lhind.lhindleap" size={300} />
						<a
							href="https://play.google.com/store/apps/details?id=com.lhind.lhindleap"
							target="_blank"
							rel="noreferrer"
						>
							<img src={PlayStoreBadge} alt="Get it on Google Play" />
						</a>
					</div>
				</div>
			</div>
		)
	}
}

ProviderStore.register(new AppInformation())
