import { LoadingIndicator, Searchbar, SearchResult as SearchResultView } from "component-library"
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import {
	PersistenceMethod,
	ProviderStore,
	SearchProvider,
	SearchResult,
	setQueryParam,
	translate,
	usePersistentState,
	useViewMode,
	ViewMode,
} from "shared"

import "./search.css"

let searchTask: SearchTask | null = null

/**
 * Search view using the search provider from the provider store
 *
 * @export
 * @returns
 */
export function Search({
	open = true,
	setOpen = (_: boolean) => {},
	setSidebarOpen = (_: boolean) => {},
}: {
	open?: boolean
	setOpen?: (open: boolean) => void
	setSidebarOpen?: (open: boolean) => void
}) {
	const history = useHistory()
	const viewMode = useViewMode()

	const [searchTerm, setSearchTerm] = usePersistentState("", "query", PersistenceMethod.QUERY)
	const [searchResults, setSearchResults] = useState<SearchResult[]>()
	const [loading, setLoading] = useState(false)
	const [loadtime, setLoadtime] = useState<number>(0)

	const [itemsVisible, setItemsVisible] = useState(20)

	const resultsRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!!searchTask) {
			searchTask.cancel()
		}

		const newSearchTask = new SearchTask(ProviderStore.getSearchProviders(), setSearchResults, setLoading, setLoadtime)
		searchTask = newSearchTask
		window.setTimeout(() => {
			newSearchTask.start(searchTerm)
			setItemsVisible(20)
		}, 300)
	}, [searchTerm])

	useEffect(() => {
		if (searchResults && searchResults.length > 0) {
			setOpen(true)
		}
	}, [searchResults])

	useEffect(() => {
		ProviderStore.emitEvent("search-open", open)
	}, [open])

	return (
		<>
			{open && (
				<div className={`search-bar--${viewMode === ViewMode.HIDDEN ? "mobile" : "desktop"}`}>
					<Searchbar
						iconColor="#fff"
						iconFocusOutColor="#fff"
						className="search-bar"
						kind="primary"
						size="small"
						onInput={value => {
							setSearchTerm(value)
							setOpen(true)
							setQueryParam("query", value, history, true)
						}}
						additional={{ value: searchTerm }}
					/>
				</div>
			)}
			{searchTerm.length > 0 && searchResults && (viewMode !== ViewMode.HIDDEN || open) && (
				<div
					className={[
						"search-result-list-background",
						`search-result-list-background--${viewMode === ViewMode.HIDDEN ? "mobile" : "desktop"}`,
					].join(" ")}
					onClick={() => setSearchTerm("")}
				>
					<div className="search-result-list-wrapper">
						<div
							className="search-result-list"
							ref={resultsRef}
							onScroll={scrollEvent => {
								const scrollPos =
									(scrollEvent.target as HTMLDivElement).scrollTop + (scrollEvent.target as HTMLDivElement).clientHeight
								const maxScroll = (scrollEvent.target as HTMLDivElement).scrollHeight

								if (scrollPos > maxScroll - 150 && itemsVisible < (searchResults?.length ?? 0)) {
									setItemsVisible(itemsVisible + 2)
								}
							}}
						>
							{searchTerm.length > 0 && searchResults && (
								<div className="search-result-amount">
									{searchResults?.length === 1
										? translate(
												"t-search-single-result-timing--amount-time",
												searchResults?.length,
												loadtime.toFixed(3),
											)
										: translate("t-search-results-timing--amount-time", searchResults?.length, loadtime.toFixed(3))}
								</div>
							)}
							{searchTerm.length > 0 &&
								searchResults &&
								searchResults.slice(0, itemsVisible).map(result => (
									<SearchResultView
										key={result.link}
										{...result}
										searchTerm={searchTerm}
										additional={{
											onClick: () => {
												if (!!result.action) {
													result.action()
												} else if (!!result.link) {
													history.push(result.link)
												} else {
													console.error("Search result provided no action or link")
												}
												setSearchTerm("")
												setOpen(false)
												setSidebarOpen(false)
											},
										}}
									/>
								))}
							{loading && searchTerm.length > 0 && (
								<div className="search-loading-center">
									<LoadingIndicator position="center" />
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	)
}

class SearchTask {
	private searchProvider: SearchProvider[]
	private todo: number
	private newResults: SearchResult[] = []
	private startTime = new Date()
	private resultsCallback: (results: SearchResult[]) => void
	private loadingCallback: (loading: boolean) => void
	private loadTimeCallback: (time: number) => void
	private cancelled = false

	constructor(
		searchProvider: SearchProvider[],
		resultsCallback: (results: SearchResult[]) => void,
		loadingCallback: (loading: boolean) => void,
		loadTimeCallback: (loading: number) => void,
	) {
		this.searchProvider = searchProvider
		this.todo = searchProvider.length
		this.resultsCallback = resultsCallback
		this.loadingCallback = loadingCallback
		this.loadTimeCallback = loadTimeCallback
	}

	start(searchTerm: string) {
		if (!this.cancelled) {
			this.startTime = new Date()
			this.loadingCallback(true)
			this.resultsCallback([])
			this.searchProvider.forEach(searchProvider => {
				searchProvider
					.search(searchTerm)
					.then(results => {
						this.newResults = this.newResults.concat(results)
						this.todo--
						if (!this.cancelled) {
							this.resultsCallback(this.newResults.sort((a, b) => b.priority - a.priority))
							this.loadingCallback(this.todo !== 0)
							this.loadTimeCallback((new Date().getTime() - this.startTime.getTime()) / 1000)
						}
					})
					.catch(() => {
						console.error(this, "Search task failed")
					})
			})
			const updateTime = () => {
				if (this.todo !== 0 && !this.cancelled) {
					this.loadTimeCallback(Math.floor((new Date().getTime() - this.startTime.getTime()) / 100) / 10)
					window.setTimeout(updateTime, 100)
				}
			}
			updateTime()
			this.loadingCallback(this.todo !== 0)
		}
	}

	cancel() {
		this.cancelled = true
	}
}
