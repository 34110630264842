import { faCogs } from "@fortawesome/free-solid-svg-icons/faCogs"
import { faGlobeAfrica } from "@fortawesome/free-solid-svg-icons/faGlobeAfrica"
import { SearchResult, SearchHelper, Searcher, translate, ProviderStore } from "shared"

export class SettingsSearchProvider extends SearchHelper {
	uniqueId = "base-settings"
	search = async (input: string): Promise<SearchResult[]> => {
		return this.resultsFrom(
			[
				new Searcher({
					title: translate("t-settings-page-title"),
					icon: faCogs,
					link: "/settings",
					priority: 0,
				}).canInclude("t-search-term-settings", 90),
				new Searcher({
					title: translate("t-language-page-settings-title"),
					icon: faGlobeAfrica,
					link: "/settings?tab=general",
					priority: 0,
				})
					.canInclude("t-search-term-settings", 70)
					.canInclude("t-search-term-language", 90),
			],
			input,
		)
	}
}

ProviderStore.register(new SettingsSearchProvider())
