import { useEffect, useState } from "react"
import { Notification as NotificationModel, useNotifications } from "shared"
import { Notification } from "component-library"
import "./notifications.css"
import { v4 as uuidv4 } from "uuid"

/**
 * Lazy loads extension modules based on a request to the Service Avilability
 * Service that is currently mocked using the extensionModules.txt file
 *
 * @param {{ children: any }} props React components
 * @returns
 */
export const NotificationProvider = (props: {
	children: any
	onInit: (func: (notification: NotificationModel) => void) => void
}) => {
	const { setConsumer } = useNotifications()

	const [notifications, setNotifications] = useState<NotificationModel[]>([])
	const [showId, setShowId] = useState<string | null>(null)
	const [hideId, setHideId] = useState<string | null>(null)
	const [removeId, setRemoveId] = useState<string | null>(null)

	useEffect(() => {
		if (showId !== null) {
			setNotifications(notifications =>
				notifications.map(n => {
					if (n.id === showId) {
						n.hidden = false
					}
					return n
				}),
			)
			setShowId(null)
		}
	}, [showId])

	useEffect(() => {
		if (hideId !== null) {
			setNotifications(notifications =>
				notifications.map(n => {
					if (n.id === hideId) {
						n.hidden = true
						n.onHide()
						window.setTimeout(() => {
							setRemoveId(n.id)
						}, 200)
					}
					return n
				}),
			)
			setHideId(null)
		}
	}, [hideId])

	useEffect(() => {
		if (removeId !== null) {
			setNotifications(notifications => notifications.filter(n => n.id !== removeId))
			setRemoveId(null)
		}
	}, [removeId])

	function notificationConsumer(notification: NotificationModel) {
		notification.id = uuidv4()

		setNotifications(notifications => notifications.concat(notification))
		setShowId(notification.id)

		if (notification.shouldCloseAutomatically) {
			let timeout = notification.closeAfterSeconds

			if (!timeout) {
				switch (notification.kind) {
					case "success":
						timeout = 8
						break
					case "info":
						timeout = 6
						break
					case "warning":
						timeout = 13
						break
					case "error":
						timeout = 16
						break
				}

				if (notification.options) {
					timeout += notification.options.length * 3
				}
			}

			window.setTimeout(() => {
				setHideId(notification.id)
			}, timeout * 1000)
		}
	}

	setConsumer(notificationConsumer)

	useEffect(() => {
		props.onInit(notificationConsumer)
	}, [])

	return (
		<>
			{props.children}
			<div className="notification-container">
				{notifications.map(notification => (
					<Notification
						id={"notification-" + notification.id}
						key={"notification-" + notification.id}
						title={notification.title}
						text={notification.text}
						kind={notification.kind}
						icon={notification.icon}
						iconColor={notification.iconColor}
						closeCallback={() => {
							notification.onCloseClicked()
							setHideId(notification.id)
						}}
						hidden={notification.hidden}
						clearAllCallback={() => {
							notifications.forEach(n => {
								n.hidden = true
								n.onHide()
								window.setTimeout(() => {
									setRemoveId(n.id)
								}, 200)
							})
						}}
						clearAll={notifications.length > 1 && notifications[0].id === notification.id}
						options={notification.options?.map(option => {
							return {
								text: option.text,
								callback: () => {
									setHideId(notification.id)
									option.callback()
								},
							}
						})}
					/>
				))}
			</div>
		</>
	)
}
