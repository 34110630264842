import { ProviderStore, translate, useI18n, ViewProvider } from "shared"
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog"
import { Button, LanguageSelect } from "component-library"
import { useState } from "react"
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export class GeneralSettings extends ViewProvider {
	groupName = "settings"
	name = translate("t-settings-general-tab-title")
	icon = faCog
	priority = 1000
	uniqueId = "general"
	component = () => {
		const { updateLanguage, language, languages } = useI18n()
		const [languageChanged, setLanguageChanged] = useState(false)

		return (
			<div>
				<h3>{translate("t-settings-language-section-title")}</h3>
				<LanguageSelect
					languages={languages.map(l => l.name)}
					selectedLanguage={language.name}
					selectCallback={language => {
						updateLanguage(language)
						setLanguageChanged(true)
					}}
				/>
				{languageChanged && (
					<Button
						icon={faCheck}
						label={translate("t-action-apply")}
						kind="primitive"
						size="medium"
						additional={{ onClick: () => window.location.reload() }}
					/>
				)}
				{ProviderStore.getViewProviders("general-settings").providers.map(provider => (
					<div key={provider.uniqueId}>
						<h3>
							{!!provider.icon && <FontAwesomeIcon icon={provider.icon} />}
							{provider.name}
						</h3>
						<provider.component />
						<br />
					</div>
				))}
			</div>
		)
	}
}

ProviderStore.register(new GeneralSettings())
