import React from "react"
import { Redirect, Route } from "react-router"
import { ProviderStore } from "shared"

import { LoadingIndicator } from "component-library"
import App from "../app/app"
import { Settings } from "../settings/settings"
import "./router.css"

/**
 * Manages routing on the root level
 *
 * @returns
 */
function Router() {
	const rootProviders = ProviderStore.getViewProviders("root").providers.sort((a, b) => b.priority - a.priority)
	return (
		<>
			<App>
				{rootProviders.map(provider => (
					<Route
						key={`/${provider.uniqueId}`}
						path={`/${provider.uniqueId.toLowerCase().replaceAll(" ", "-")}`}
						component={provider.component}
					/>
				))}
				<Route exact path={`/`}>
					{rootProviders.length > 0 ? (
						<Redirect to={`/${rootProviders[0].uniqueId.toLowerCase().replaceAll(" ", "-")}`} />
					) : (
						<LoadingIndicator position="center" />
					)}
				</Route>
				<Route path={`/settings`} component={Settings}></Route>
			</App>
		</>
	)
}

export default Router
