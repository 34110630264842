import "bootstrap/dist/css/bootstrap.min.css"

import React from "react"
import ReactDOM from "react-dom"

import Router from "./router/router"
import setupWebrequests from "./helper/authenticateRequests"
import Keycloak from "keycloak-js"
import { ProviderStoreProvider } from "./provider/provider"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import { BrowserRouter } from "react-router-dom"
import { Notification, readEnvironment } from "shared"
import { NotificationProvider } from "./helper/notifications"
import { KeycloakLoadingIndicator } from "./helper/keycloakLoadingIndicator"

export * from "./settings"
export * from "./provider"

const keycloak = Keycloak({
	url: "https://" + readEnvironment("REACT_APP_KEYCLOAK_URL"),
	realm: readEnvironment("REACT_APP_KEYCLOAK_REALM"),
	clientId: readEnvironment("REACT_APP_KEYCLOAK_CLIENT"),
})

/*keycloak.init({ onLoad: 'check-sso', enableLogging: true }).then((authenticated) => {

	if (!authenticated) {
		//keycloak.login()
	} else {
		console.info("Authenticated");
	}
})*/

let notificationConsumer: (notification: Notification) => void

/**
 * Handles keycloak events, starts authentication and adds tokens to web requests
 *
 * @param {('onReady' | 'onInitError' | 'onAuthSuccess' | 'onAuthError' | 'onAuthRefreshSuccess' | 'onAuthRefreshError' | 'onAuthLogout' | 'onTokenExpired')} eventType
 */
function handleKeycloakEvents(
	eventType:
		| "onReady"
		| "onInitError"
		| "onAuthSuccess"
		| "onAuthError"
		| "onAuthRefreshSuccess"
		| "onAuthRefreshError"
		| "onAuthLogout"
		| "onTokenExpired",
) {
	switch (eventType) {
		case "onReady":
			if (!keycloak.authenticated) {
				keycloak.login()
			} else if (!!keycloak.token) {
				setupWebrequests(keycloak.token, notificationConsumer)
			}
			break
		case "onAuthSuccess":
		case "onAuthRefreshSuccess":
			if (!!keycloak.token) {
				setupWebrequests(keycloak.token, notificationConsumer)
			}
			break
		case "onTokenExpired":
			keycloak.updateToken(5)
			break
		case "onAuthLogout":
			keycloak.login()
			break
	}
}

const maintenanceMode = readEnvironment("REACT_APP_MAINTENANCE") !== "off"

if (maintenanceMode) {
	ReactDOM.render(
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				width: "100vw",
				margin: 0,
				backgroundColor: "#fafafa",
				color: "#aaa",
				flexDirection: "column",
			}}
		>
			<h1 style={{ marginBlockEnd: "0.3em" }}>
				<b>Maintenance in progress</b>
			</h1>
			<h2>LEAP will be back shortly</h2>
		</div>,
		document.getElementById("root"),
	)
} else {
	ReactDOM.render(
		<React.StrictMode>
			<NotificationProvider
				onInit={consumer => {
					notificationConsumer = consumer
				}}
			>
				<ReactKeycloakProvider
					authClient={keycloak}
					initOptions={{ checkLoginIframe: false }}
					onEvent={handleKeycloakEvents}
				>
					<KeycloakLoadingIndicator>
						<ProviderStoreProvider>
							<BrowserRouter basename="/">
								<Router />
							</BrowserRouter>
						</ProviderStoreProvider>
					</KeycloakLoadingIndicator>
				</ReactKeycloakProvider>
			</NotificationProvider>
		</React.StrictMode>,
		document.getElementById("root"),
	)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
